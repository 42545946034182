<template>
  <div>
    <material-card
      :title="$t('configOrderHoldingTime.title')"
      color="green"
      flat
      full-width
      class="bg-white"
    >
      <v-form ref="form">
        <v-container py-0>
          <v-layout wrap>
            <v-flex
              xs12
              sm8
              md6
            >
              <v-text-field
                v-model="holdingTime"
                :label="$t('configOrderHoldingTime.paymentOverdueTime')"
              />
            </v-flex>
            <v-flex
              xs12
              sm8
              md6
            >
              <v-text-field
                v-model="cancelingTime"
                :label="$t('configOrderHoldingTime.orderCancellationTime')"
              />
              <span style="font-size: 12px;"><span class="red--text">* </span>{{ $t('configOrderHoldingTime.calculatedTimePaymentOverdue') }}</span>
            </v-flex>
          </v-layout>
          <v-btn
            style="margin-right: 0;"
            class="pull-right"
            color="success"
            @click="onCreateOrUpdateHoldingTimeConfig"
          >
            <span class="btn-label mr-3">
              <i class="glyphicon glyphicon-ok" />
            </span>
            <span style="text-transform: none;">{{
              $t("common.save")
            }}</span>
          </v-btn>
        </v-container>
      </v-form>
    </material-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import stringUtils from 'utils/stringUtils'
import ToastType from 'enum/toastType'
export default {
  data: () => ({
    holdingTime: 0,
    cancelingTime: 0,
    entityType: null,
    entityId: null,
    objectSettingKey: stringUtils.ObjectSettingKey
  }),
  computed: {
    ...mapGetters([
      'OBJECT_SETTING_DATA',
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    OBJECT_SETTING_DATA () {
      let data = this.OBJECT_SETTING_DATA
      // Holding time data
      let holdingTimeData = data.filter(element => {
        return element.key === this.objectSettingKey.HOLDING_TIME
      })
      if (holdingTimeData.length > 0) {
        this.holdingTime = holdingTimeData[0].value
      }
      // Canceling time data
      let cancelingTimeData = data.filter(element => {
        return element.key === this.objectSettingKey.CANCELING_TIME
      })
      if (cancelingTimeData.length > 0) {
        this.cancelingTime = cancelingTimeData[0].value
      }
    }
  },
  created () {
    this.entityId = sessionStorage.getItem('entityId')
    this.entityType = sessionStorage.getItem('entityType')
    let filter = {
      params: {
        objectId: this.entityId,
        objectType: 'Entity'
      }
    }
    this.GET_OBJECT_SETTING(filter)
  },
  methods: {
    /**
     * Create or update holdingTime config
     */
    onCreateOrUpdateHoldingTimeConfig: function (event) {
      event.preventDefault()
      let filter = {
        objectId: this.entityId,
        objectType: 'Entity',
        key: this.objectSettingKey.HOLDING_TIME,
        value: this.holdingTime
      }
      this.CREATE_OBJECT_SETTING(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
        }.bind(this)
      )
      filter['key'] = this.objectSettingKey.CANCELING_TIME
      filter['value'] = this.cancelingTime
      this.CREATE_OBJECT_SETTING(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
        }.bind(this)
      )
    },
    ...mapActions([
      'CREATE_OBJECT_SETTING',
      'GET_OBJECT_SETTING',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style>

</style>
