<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <div class="mt-4">
          <tab-header
            :tab-items="tabItems"
            @onSelectTab="onSelectTab" />
        </div>
        <material-card
          v-if="tabSelected == tabs.PAYMENT_METHOD"
          :title="$t('paymentMethod.list')"
          color="green"
          flat
          full-width
        >
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="paymentMethodHeaders"
            :items="paymentMethodsList"
            :single-select="true"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.stt }}</td>
              <td>{{ $t(getPaymentMethodName(item.id)) }}</td>
              <td><span style="white-space: pre-wrap;">{{ item.description }}</span></td>
              <td>
                <v-switch
                  v-model="item.status"
                  readonly />
              </td>
              <td class="text-xs-center">
                <!-- Update voucher -->
                <v-tooltip
                  top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      fab
                      small
                      color="success"
                      v-on="on"
                      @click="onGoTransferInfo(item.id, item.paymentId)"
                    >
                      <v-icon dark>mdi-cog-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("paymentMethod.setting") }}</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
          <pagination
            :total="paymentMethodPaginate.totalPage"
            :current-page="paymentMethodPaginate.currentPage"
            :row-per-page="paymentMethodPaginate.rowPerPage"
            @onPageChange="onPageChange"
          />
        </material-card>
        <material-card
          v-if="tabSelected == tabs.APPROVE_ORDER"
          :title="$t('productOrder.btn.orderConfirmation')"
          color="green"
          flat
          full-width
        >
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="storeChannelHeaders"
            :items="storeChannelsList"
            :single-select="true"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.stt }}</td>
              <td>{{ item.storeChannel }}</td>
              <td>
                <v-switch
                  v-model="item.autoApprove"
                  @change="onAutoApproveChange(item)"
                />
              </td>
            </template>
          </v-data-table>
        </material-card>
        <!-- <v-container v-if="tabSelected == tabs.TIMER_SETTING"> -->
        <config-order-holding-time v-if="tabSelected == tabs.TIMER_SETTING" />
        <!-- </v-container> -->
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
    <confirmModal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onDeleteCategory"/>
    <loadingBar :is-loading="isLoading" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ToastType from 'enum/toastType'
import ConfirmModal from 'Components/ConfirmModal'
import functionUtils from 'utils/functionUtils'
import ConfigOrderHoldingTime from 'Views/Portal/Config/OrderHoldingTime'
import TabHeader from 'Components/TabHeader.vue'
export default {
  components: {
    ConfirmModal,
    ConfigOrderHoldingTime,
    TabHeader
  },
  data: () => ({
    paymentMethodHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'paymentMethod.title',
        value: 'id'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.description',
        value: 'description',
        align: 'center'
      },
      {
        sortable: false,
        text: 'paymentMethod.turnOn',
        value: 'status'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    storeChannelHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'Kênh bán',
        value: 'storeChannel'
      },
      {
        sortable: false,
        text: 'Duyệt đơn tự động',
        value: 'autoApprove'
      }
    ],
    storeChannelsList: [],
    paymentMethodsList: [],
    paymentMethodPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    confirmModalTitle: null,
    isLoading: false,
    valid: false,
    tabs: {
      PAYMENT_METHOD: 1,
      APPROVE_ORDER: 2,
      TIMER_SETTING: 3
    },
    tabItems: [
      {
        title: 'paymentMethod.title',
        id: 1
      },
      {
        title: 'productOrder.btn.orderConfirmation',
        id: 2
      },
      {
        title: 'configOrderHoldingTime.title',
        id: 3
      }
    ],
    tabSelected: 1
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'STORE_CHANNELS_WITH_AUTO_APPROVE_DATA'
    ])
  },
  watch: {
    STORE_CHANNELS_WITH_AUTO_APPROVE_DATA () {
      let data = this.STORE_CHANNELS_WITH_AUTO_APPROVE_DATA
      this.storeChannelsList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          stt: i + 1,
          id: data[i].id,
          storeChannel: data[i].name,
          autoApprove: data[i].auto_approve_order
        }
        this.storeChannelsList.push(obj)
      }
    }
  },
  created () {
    this.getPaymentMethodsList()
    this.GET_STORE_CHANNELS_WITH_AUTO_APPROVE()
  },
  methods: {
    /**
     * Auto approve change
     */
    onAutoApproveChange: function (item) {
      let filter = {
        storeChannelId: item.id,
        autoApproveOrders: item.autoApprove
      }
      this.ENABLE_DISABLE_AUTO_APPROVE(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      )
    },
    /**
     * Select tab
     */
    onSelectTab: function ({ index, item }) {
      this.tabSelected = item.id
    },
    /**
     * Go transfer info
     */
    onGoTransferInfo: function (paymentMethod, paymentId) {
      sessionStorage.setItem('paymentId', paymentId)
      this.$router.push({
        name: 'paymentMethod.transferInfo',
        params: {
          id: paymentMethod
        }
      })
    },
    /**
     * Show modal add category
     */
    onShowModalAddStoreChannel: function (id) {
      this.$refs.addStoreChannelModal.onShowModal(id)
    },
    /**
     * Create category success
     */
    onCreateStoreChannelSuccess: function () {
      this.getPaymentMethodsList()
    },
    /**
     * Delete domain
     */
    onDeleteCategory: function () {
      this.DELETE_STORE_CHANNEL({ id: this.storeChannelId }).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getPaymentMethodsList()
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              message: this.$t(errorText),
              styleType: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paymentMethodPaginate.currentPage = page
      this.getPaymentMethodsList()
    },
    /**
     * Get domain
     */
    getPaymentMethodsList: function () {
      this.GET_PAYMENT_METHODS_LIST().then(
        function (res) {
          let data = res.data
          this.paymentMethodsList = []
          for (let i = 0, size = data.length; i < size; i++) {
            let obj = {
              stt: i + 1,
              id: data[i].payment_type,
              status: data[i].payment_info.enabled,
              description: data[i].payment_info.description,
              paymentId: data[i].payment_info.id ? data[i].payment_info.id : null
            }
            this.paymentMethodsList.push(obj)
          }
        }.bind(this)
      )
    },
    getPaymentMethodName: function (method) {
      return functionUtils.paymentMethodTypeName(method)
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_PAYMENT_METHODS_LIST',
      'DELETE_STORE_CHANNEL',
      'GET_STORE_CHANNELS_WITH_AUTO_APPROVE',
      'ENABLE_DISABLE_AUTO_APPROVE'
    ])
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.banner-img-class {
  width: 80%;
  cursor: pointer;
}
input[type="file"] {
  display: none;
}
.remove-banner-img {
  color: #ef5350;
  cursor: pointer;
}
.remove-banner-img:hover {
  text-decoration: underline;
}
/deep/.color-review {
  width: 100%;
  height: 305px;
  max-height: 305px;
  border: 1px solid #ddd;
  border-radius: 4px;
  caret-color: auto;
  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
